<template>
    <button @click="linkedInLogin()" type="button" class="social-login">
		<span class="btn-social-login">
				<nuxt-icon name="linkedin" class="social-icon" />
				<span class="google-text">Login with LinkedIn</span>
		</span>
    </button>
</template>

<script setup lang="ts">
	import { SessionKeys } from '~/utils/enums';

	const emit = defineEmits(['successLogin']);
	const redirectUri = window.location.origin;
	const config = useRuntimeConfig();
	const clientId = config.public.linkedinClientId;

	function linkedInLogin() {
		// Why is this not typed using zod?
		// Parameters to pass to OAuth 2.0 endpoint.
		var params = {
			'client_id': clientId,
			'redirect_uri': redirectUri,
			'response_type': 'code',
			'scope': 'openid,profile,email',
			'include_granted_scopes': 'true',
			'state': '987654321'
		};

		// Store current page to come back to after login only if postLoginRedirectUrl is not in localstorage already.		
		const existingPostLoginRedirectUrl = localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/';
		//console.log('linkedIn existingPostLoginRedirectUrl', existingPostLoginRedirectUrl);
		if (existingPostLoginRedirectUrl === '/') {			
			let postLoginRedirectUrl = window.location.href;
			if(postLoginRedirectUrl.includes('/account/')) {
				console.log('linkedIn postLoginRedirectUrl', postLoginRedirectUrl);
				postLoginRedirectUrl = '/';
			}
			
			localStorage.setItem(SessionKeys.PostLoginRedirectUrl, ensureRelativeURL(postLoginRedirectUrl));
		}

		const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=${params.response_type}&state=${params.state}&scope=${params.scope}&client_id=${params.client_id}&redirect_uri=${params.redirect_uri}`
		window.location.href = linkedInAuthUrl;
		}
</script>