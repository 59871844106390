<template>
	<div class="form-group w-full">
		<label :for="inputId" class="contact-form-label">
			{{ labelText }}
		</label>
		<div class="password-input-container">
			<template v-if="props.inputId.includes('password')">
				<input  :id="props.inputId"
						:type="props.type" 
						class="login-contact-form-input password-input absolute"
						:placeholder="placeholderText"
						:value="props.modelValue"
						:required="props.isRequired"
						@input="handleInput" />
			</template>
			<template v-else >
				<input 	:id="props.inputId"
						:type="props.type"
						class="login-contact-form-input"
						:placeholder="placeholderText"
						:value="modelValue"
						:required="props.isRequired"
						@input="handleInput" />
			</template>
			
			<div class="password-visibility-container" v-if="props.inputId.includes('password')">
				<span class="password-toggle-icon" @click="identifyInputbox(props.inputId)">
					<NuxtLink class="mobile-search-icon">
						<nuxt-icon v-if="showPassword == false" name="password-icon-hidden" />
						<nuxt-icon v-else name="password-icon-show" />
					</NuxtLink>
				</span>
			</div>
		</div>
		<p :id="`${inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
		<!-- <p class="hint-text">{{ hint }}</p>  -->
	</div>
</template>  

<script setup lang="ts">
import { isValid } from 'zod';

const props = defineProps({
	type: {
		type: String,
		required: true,
	},
	inputId: {
		type: String,
		required: true,
	},
	labelText: {
		type: String,
		required: true,
	},
	placeholderText: {
		type: String,
		default: '',
	},
	hint: {
		type: String,
		default: '',
	},
	isRequired: {
		type: Boolean,
		default: false,
	},
	modelValue: {
		type: [String, Number],
		default: '',
	},
	validator: {
		type: Validation,
		default: null
	},
	validatorRuleId: {
		type: String,
		default: null
	}
});

const showPassword = ref(false),
	input = ref<HTMLInputElement>(),
	enteredPassword = ref<HTMLInputElement>()

function identifyInputbox(id: string) {
	enteredPassword.value = document.getElementById(id)! as HTMLInputElement;
	togglePasswordVisibility(enteredPassword.value);
};

function togglePasswordVisibility(passwordElement: HTMLInputElement) {
	input.value = passwordElement;	
	if (input) {
		input.value.type = showPassword.value ? 'password' : 'text';
		showPassword.value = !showPassword.value;
	}
}

const inputValue = ref('');

const handleInput = (event: Event) => {
	inputValue.value = (event.target as HTMLInputElement).value;

	if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		props.validator.setValidationRuleSourceValue(props.validatorRuleId, inputValue.value);
		let resultList = props.validator.validateRule(props.validatorRuleId);
		ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId), resultList);
	}

	emitFormInput('input', inputValue.value);
};

const emitFormInput = defineEmits<{
	input: [email: string]
}>();

function ApplyValidate(isInvalid: boolean, validationResultList: Array<IValidationResult>) {
	const inputElement = document.getElementById(props.inputId) as HTMLElement;
	const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;

	if (isInvalid) {
		inputElement.classList.add("has-error");
	}
	else {
		inputElement.classList.remove("has-error");
	}

	let errorString = '';
	if (typeof (props.validator) !== 'undefined' && props.validator != null) {
		errorString = props.validator.getValidationResultString(props.validatorRuleId);
	}
	errorElement.innerText = errorString;
}
</script>

