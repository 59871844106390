<template>
    <button type="button" class="social-login" :disabled="!isReady" @click="() => login()" >
		<span class="btn-social-login">
				<nuxt-icon name="google" class="social-icon" />
				<span class="google-text">Login with Google</span>
		</span>
    </button>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~/stores/notification';
import { useTokenClient, type AuthCodeFlowSuccessResponse, type AuthCodeFlowErrorResponse } from "vue3-google-signin";
import { SessionKeys } from '~/utils/enums';

	const router = useRouter();
	const emit = defineEmits(['successLogin']);
	const store = useNotificationStore();
	
	const handleOnSuccess = async (response: AuthCodeFlowSuccessResponse) => {
		//console.log("Google Access Token: ", response.access_token);		
		// Store current page to come back to after login only if postLoginRedirectUrl is not in localstorage already.		
		const existingPostLoginRedirectUrl = localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/';
		//console.log('google login existingPostLoginRedirectUrl', existingPostLoginRedirectUrl);
		if (existingPostLoginRedirectUrl === '/') {			
			let postLoginRedirectUrl = window.location.href;
			if(postLoginRedirectUrl.includes('/account/')) {
				console.log('google login postLoginRedirectUrl', postLoginRedirectUrl);
				postLoginRedirectUrl = '/';
			}
			
			localStorage.setItem(SessionKeys.PostLoginRedirectUrl, ensureRelativeURL(postLoginRedirectUrl));
		}

		emit('successLogin', true);
		await router.push(`/?access_token=${response.access_token}`)
			.catch(error => {
				console.error('Error during route navigation:', error);
			});
	};
	
	const handleOnError = (errorResponse: AuthCodeFlowErrorResponse) => {
		// //console.log('Error Response:', JSON.stringify(errorResponse, null, 2));
		const notification: INotification = {
			type: "error",
			title: "Google login unsuccessful.",
			body: errorResponse.toString(),
			date: new Date(),
			timeout: true,
			timer: 10000
		};
		store.addNotification(notification);
		emit('successLogin', false);
	};

	const { isReady, login } = useTokenClient({
		onSuccess: handleOnSuccess,
		onError: handleOnError,
		// other options
	});
</script>