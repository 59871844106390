<template>
	<div class="gibs-login-wrapper">
		<div class="gibs-login-containers">
			<div id="gibs-login-form" class="gibs-login-form">
				<p class="login-modal-title" v-if="!$route.path.includes('account')">Sign in or <br /> Create an account</p>
				<p class="login-modal-title" v-else-if="isLoginModal">Sign in</p>
				<form @submit.prevent="submitForm">
					<GibsControlLoginFormInput 
						type="email" class="w-100" labelText="Email address"
						:inputId="props.componentId + '-email'"
						:placeholderText="'name\@email.com'"
						v-model="form.email"
						:isRequired="true"
						:validator="validation"
						:validator-rule-id="useNuxtApp().$EnumLoginFormValidatorId.LoginEmail"
						@input="emailInput">
					</GibsControlLoginFormInput>
					<GibsControlLoginFormInput type="password" class="w-100" :inputId="props.componentId + '-password'"
						labelText="Password" placeholderText="Password" v-model="form.password" :is-required="true"
						:validator="validation" :validator-rule-id="useNuxtApp().$EnumLoginFormValidatorId.LoginPassword"
						@input="passwordInput">
					</GibsControlLoginFormInput>
					<div class="login-form-link-box">
						<GibsControlFormCheckbox type="checkbox" :inputId="props.componentId + '-subscribe'"
							labelText="Remember me" checkboxGroupClass="login-form-footer-wrapper" v-model="rmCheck.checked"
							@input="subscribeInput">
						</GibsControlFormCheckbox>
						<a class="form-hyperlink login-form-hyperlink" @click="forgotPasswordEmit()" type="button">
							Forgot password?
						</a>
					</div>
					<div class="vertical-spacing h-2"></div>
					<div class="btn-login">
						<GibsControlPrimaryButton :primaryButtonSpec="loginSubmit" type="submit"  :disabled="IsBusyLoading || !validation.isValid()"/>
					</div>
					<div class="sign-up-hyperlink-wrapper" v-if="!$route.path.includes('account')">
						<NuxtLink class="form-hyperlink" to="/account/sign-up" @click="openAccountSignUp">Create an account</NuxtLink>
					</div>
					<div class="divider-container">
						<hr class="word-line">
						<span class="word-divider">or</span>
						<hr class="word-line">
					</div>

					<div class="gibs-social-login-container">
						<GibsGoogleLogin @successLogin="socialLoginCompleted" :disabled="IsBusyLoading" @click="setloading()" />
						<GibsLinkedinLogin @successLogin="socialLoginCompleted" :disabled="IsBusyLoading" @click="setloading()" />
					</div>
					
					<div class="form-footer-wrapper">
						<p class="login-form-footer">By continuing, you agree to the <NuxtLink to="/pages/legal">Terms, Conditions and Privacy policy</NuxtLink>.
						</p>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useNotificationStore } from '~/stores/notification';
import { useReCaptcha, VueReCaptcha } from "vue-recaptcha-v3";
import { SessionKeys } from '~/utils/enums';
import { userSchema } from '~/schemas/user-schema';
import { MyLearningsQuery, EnrolmentQuery} from '~/schemas/my-learnings-query';
import GibsControlLoading from '~/components/gibs/control/loading.vue';
import { isServerInfoMode } from '~/utils/display-utils';

const router = useRouter();
const route = useRoute();
const store = useNotificationStore();
const formStore = useFormsStore();
const fpStore = useForgotPasswordStore();
const app = useNuxtApp();
const defaultTimeout = 60000;

const loginFormElement: Ref<HTMLElement | null> = ref(null);
const loadingRef = ref<typeof GibsControlLoading>();

// ReCaptcha set up in main.js
// Activate and deactivate ReCaptcha as needed
const config = useRuntimeConfig();
const recaptchaSiteKey = config.public.googleRecaptchaSiteKey;
const { vueApp } = useNuxtApp();

vueApp.use(VueReCaptcha, {
	siteKey: recaptchaSiteKey,
	loaderOptions: {
		autoHideBadge: true,
		renderParameters: {
			hl: "en",
		},
	},
});
const recaptchaInstance = useReCaptcha();

onMounted(async () => {
	loadingRef.value = formStore.getLoader().value
	// Remove '?local=true' from the URL when the component is mounted
	const { local, ...remainingQuery } = route.query;
	if (local) {
		await router.push({ path: route.path, query: remainingQuery })
			.catch(error => {
				console.error('Error during route navigation:', error, 'route.path:', route.path);
			});
	}

    let request = {request: '/api/auth/user', date: new Date(), status: 'Busy'};
    if (loadingRef.value != undefined || loadingRef.value != null)
    {
        loadingRef.value.setFetchData(request);
    }
	const userSessionData = await $fetch('/api/auth/user');
	if (loadingRef.value != undefined || loadingRef.value != null)
    {
        loadingRef.value.removeFetchData(request);
    }

    const parsedData = userSchema.safeParse(userSessionData);
	const isLoggedIn = parsedData.success ? parsedData.data.isLoggedIn : false;
	if (isLoggedIn) {
		await router.push("/")
			.catch(error => {
				console.error('Error during route navigation:', error, 'route.path ', "/");
			});
	}

	loginSubmit.value.disabled = !validation.isValid() || loadingRef.value.IsBusyLoading();
	loginFormElement.value = document.getElementById("gibs-login-form");
	if(user.value){
		rmCheck.value.checked = user.value.rememberMe;
		form.value.email = user.value.username;

		validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginEmail, form.value.email);
		validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginPassword, form.value.password);
		loginSubmit.value.disabled = !validation.isValid();
	}
	await recaptchaInstance?.recaptchaLoaded(); // Wait for recaptcha data
	recaptchaInstance?.instance.value?.showBadge();
	
}),
onBeforeUnmount(async () => {
	const route = useRoute().fullPath.toString();
	if (!route.includes("/account")) {
		recaptchaInstance?.instance.value?.hideBadge();
		let request = {request: '/api/auth/user', date: new Date(), status: 'Busy'};
		if (loadingRef.value != undefined || loadingRef.value != null)
		{
			loadingRef.value.setFetchData(request);
		}
		// redirect to home page if user is logged in
		const userSessionData = await $fetch('/api/auth/user');

		if (loadingRef.value != undefined || loadingRef.value != null)
		{
			loadingRef.value.removeFetchData(request);
		}

		const parsedData = userSchema.safeParse(userSessionData);
		const isLoggedIn = parsedData.success ? parsedData.data.isLoggedIn : false;
		if (isLoggedIn) {
			const postLoginRedirectUrl = localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/';
			//localStorage.removeItem('postLoginRedirectUrl');
			await router.push(ensureRelativeURL(postLoginRedirectUrl))
			.catch(error => {
				console.error('Error during route navigation:', error, 'route.path ', postLoginRedirectUrl);
			});
		}
	}
})

// Implement cookie for username email
const user = useCookie<{
	rememberMe: boolean,
	username: string
} | null>("user");

type ElementWithNewProperties<T> = Partial<T> & {
	checked?: boolean,
	value?: string
}

const props = defineProps(['userNameProps', 'componentId']);
const emit = defineEmits(['successLogin']);

const loginSubmit = ref<IControlButton>({
	buttonElName: `${props.componentId}-button`,
	buttonText: "Log in",
	class: "submit-login-form",
	disabled: false,
	load: false
});

const form = ref<ILoginForm>({
	email: '',
	password: '',
	token: '',
	provider: 'local'
	// remember: false,
	// extraInfo: false
});

const { loginFormValidation } = useValidations();
const validation = loginFormValidation(form.value);

loginSubmit.value.disabled = !validation.isValid();

const isLoginModal = ref<boolean>(false);
const enteredEmail = ref<ElementWithNewProperties<Element>>();
const rmCheck = ref<ElementWithNewProperties<Element>>({
	checked: false,
	value: ''
});

if (props.componentId.includes("modal")) {
	isLoginModal.value = true;
}

function forgotPasswordEmit() {
	fpStore.isOpenForgotPassword();
}

function emailInput(input: string) {
	form.value.email = input;
	validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginEmail, input);
	loginSubmit.value.disabled = !validation.isValid();
}

function passwordInput(input: string) {
	form.value.password = input;
	validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginPassword, input);
	loginSubmit.value.disabled = !validation.isValid();

	// Checks once from login form on the page. If no props then ignores
	if (props.userNameProps !== "" && props.userNameProps !== undefined && input.length === 1) {
		emailInput(props.userNameProps);
	};
}

function subscribeInput(input: boolean) {
	rmCheck.value.checked = input;

	if (input) {
		user.value = {
			rememberMe: input,
			username: form.value.email
		};
	}
	else {
		user.value = null;
	}
}

function openAccountSignUp() {
	//console.log('openAccountSignUp');
	loadingRef.value?.hideSpinner();
	loginSubmit.value.load = false;
	// Store current page to come back to after login only if postLoginRedirectUrl is not in localstorage already.		
	const existingPostLoginRedirectUrl = localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/';
	//console.log('openAccountSignUp existingPostLoginRedirectUrl', existingPostLoginRedirectUrl);
	if (existingPostLoginRedirectUrl === '/') {			
		let postLoginRedirectUrl = window.location.href;
		if(postLoginRedirectUrl.includes('/account/')) {
			console.log('openAccountSignUp postLoginRedirectUrl', postLoginRedirectUrl);
			postLoginRedirectUrl = '/';
		}
		localStorage.setItem(SessionKeys.PostLoginRedirectUrl, ensureRelativeURL(postLoginRedirectUrl));
	}
	emit('successLogin', true);
}

const userSession = useCookie<{ sessionId: string }>('userSession');

async function loginCompleted(success: boolean) {
	//console.log('localLoginCompleted', success);
	// Refresh the sessionId when logging out
  userSession.value = { sessionId: newGuid().toString() };

	loadingRef.value?.hideSpinner();
	loginSubmit.value.load = false;
	const redirectUrl = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
	console.log('localLoginCompleted redirectUrl', redirectUrl)
	if (success) {
		//localStorage.removeItem('postLoginRedirectUrl');
		await router.push(ensureRelativeURL(redirectUrl))
			.catch(error => {
				console.error('Error during route navigation:', error, 'route.path ', redirectUrl);
			});
	} else  {
		router.push(router.currentRoute.value.path);
	}
	emit('successLogin', success);
}

function socialLoginCompleted(success: boolean) {
	//console.log('socialLoginCompleted', success);
	userSession.value = { sessionId: newGuid().toString() };
	loadingRef.value?.hideSpinner();
	loginSubmit.value.load = false;
	const postLoginRedirectUrl = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
	console.log('socialLoginCompleted postLoginRedirectUrl', postLoginRedirectUrl);
	emit('successLogin', success);
}

//const submitForm = async () => {	
async function submitForm()
{
	await loadingRef.value.LoadData(async (request:any) => {
			request.status = 'ensureRelativeURL';
			let postLoginRedirectUrl = ensureRelativeURL(router.currentRoute.value.fullPath.toString());
			const currentRedirectUrl = localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/';
			if (currentRedirectUrl === '/') {
				if (postLoginRedirectUrl.includes('/account/')) {
					console.log('Login submitForm postLoginRedirectUrl', postLoginRedirectUrl);
					postLoginRedirectUrl = '/';
				}
				localStorage.setItem(SessionKeys.PostLoginRedirectUrl, postLoginRedirectUrl);
			}

			// Add '?local=true' to the URL
			await router.push({ path: route.path, query: { ...route.query, local: 'true' } })
				.catch(error => {
					request.status = 'ensureRelativeURL Error';
					console.error('Error during route navigation:', error, 'route.path:', route.path, 'route.query:', route.query);
				});

			loginSubmit.value.load = true;
			const formData: ILoginForm = {
				email: form.value.email,
				password: form.value.password,
				token: '',
				provider: 'local'
				// extraInfo: form.value.extraInfo,
				// remember: false
			};
			request.status = 'sendDataToServer';
			
			await sendDataToServer(formData);
	},defaultTimeout)
	.catch((error: any) => {
		
		let notification = {
			type: "error",
			title: "Sign in unsuccessful.",
			body: `There was an error while signing you in. Please try again.`, 
			date: new Date(),
			timeout: true,
			timer: 10000
		};
		store.addNotification(notification);
		loginCompleted(false);
	});
};

const sendDataToServer = async (formData: ILoginForm) => {
	// disable the form inputs while fetching
	const loginFormElement = document.getElementById("gibs-login-form");
	let notification: INotification;

	try {
		const { data: passwordData } = await useLoaderFetch('/api/encryption/encrypt', {
			method: 'POST',
			headers: {
				'user-text': form.value.password
			}
		});
		const passwordText = (passwordData.value == null) ? '' : passwordData.value;
		formData.password = passwordText;

		await recaptchaInstance?.recaptchaLoaded();
		const googleReCaptchaToken = await recaptchaInstance?.executeRecaptcha(
			"login"
		);

		// Only send error.value?.message if in development!
		if (process.env.NODE_ENV === 'development') {
			//console.log("Google recaptcha Token to be sent with backend call to be verified: ", googleReCaptchaToken);
		}
		
		formData.token = googleReCaptchaToken ?? "";
		formData.showServerRequest = isServerInfoMode.value();

		const { data: response, error, status } = await useLoaderFetch('/api/auth/login', {
			method: 'POST',
			body: { provider: formData.provider,  data: formData }
		});

		const isSuccess: string = 'success';
		const isError: string = 'error';

		if (status.value === isSuccess) {
			let loginResponse = LoginResponse.parse(response.value);
			if (loginResponse.success) {
				notification = {
					type: "success",
					title: "Sign in.",
					body: "You have successfully signed into your profile",
					date: new Date(),
					timeout: true,
					timer: 5000
				};

				form.value.email = '';
				form.value.password = '';

				validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginEmail, form.value.email);
				validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginPassword, form.value.password);
				loginSubmit.value.disabled = !validation.isValid();

				localStorage.setItem('DoEnrolmentMessage', 'true');

				loginCompleted(loginResponse.success);
			}
			else {
				notification = {
					type: "error",
					title: "Sign in unsuccessful.",
					body: loginResponse.error!,
					date: new Date(),
					timeout: true,
					timer: 10000
				};
				loginCompleted(false);
			}
			store.addNotification(notification);
		}
		else if (status.value === isError) {
			setTimeout(() => {
				notification = {
					type: "error",
					title: "Sign in unsuccessful.",
					body: error.value!.toString(),
					date: new Date(),
					timeout: true,
					timer:10000
				};

				store.addNotification(notification);
			}, 500);

			loginCompleted(false);
		}
	}
	catch (error) {
		notification = {
			type: "error",
			title: "Sign in unsuccessful.",
			body: `There was an error while signing you in. Please try again. ${error.message}`, //bodyText, // Only allow secret system info to be shown in development!
			date: new Date(),
			timeout: true,
			timer: 10000
		};
		store.addNotification(notification);
		loginCompleted(false);
	}
};

onMounted(() => {
	if (user.value) {
		rmCheck.value.checked = user.value.rememberMe;
		form.value.email = user.value.username;  
		validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginEmail, form.value.email);
		validation.setValidationRuleSourceValue(app.$EnumLoginFormValidatorId.LoginPassword, form.value.password);
		loginSubmit.value.disabled = !validation.isValid();
	}
})

const IsBusyLoading = computed(()=>{
	if (loadingRef.value == null) 
		return false;
	else
		return loadingRef.value.IsBusyLoading();

});

function setloading(){
	loadingRef.value.showSpinnerFor(defaultTimeout);
}
</script>