import { z } from 'zod';

export const LoginResponse = z.object({
  success: z.boolean().optional(),
  error: z.string().nullable().optional(),
  errorId: z.number().optional(),
  userID: z.string().optional(),
  jwtToken: z.string().nullable().optional(),
});

export const GoogleLoginResponse = z.object({
  sub: z.string(),
  name: z.string(),
  given_name: z.string(),
  family_name: z.string(),
  picture: z.string(),
  email: z.string(),
  email_verified: z.boolean(),
  locale: z.string(),
}).optional();

export const gibsSocialLoginResponse = z.object({
  socialLogin: z.string(),
  success: z.boolean(),
  error: z.string().nullable(),
  errorId: z.number(),
  userID: z.string(),
  jwtToken: z.string(),
}).optional();

export const FetchLoginResponse = z.object({
  data: LoginResponse.optional(),
  googleLoginResponse: GoogleLoginResponse,
  gibsSocialLoginResponse: gibsSocialLoginResponse,
  error: z.any().optional(),
  status: z.any().optional(),
});

export type FetchLoginResponseType = z.infer<typeof FetchLoginResponse>;
